@import "variables";

/*===================================
  Menu
  ===================================*/
.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.topbar .topbar-left {
  float: left;
  position: relative;
  width: 240px;
  z-index: 1;
  background-color: $primary;
  max-height: 70px;
}

.topbar .topbar-left-sm {
  width: 180px;
}

.topbar .topbar-left-sm + .navbar-custom {
  margin-left: 180px;
}

.logo {
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 70px;
  text-transform: uppercase;
}

.logo h1 {
  height: 50px;
  margin: 0 auto;
  text-align: center;
}

.logo .icon-c-logo {
  display: none;
}

.navbar-custom {
  background-color: #000;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 240px;
  min-height: 70px;
}

.navbar-custom .nav-link {
  padding: 0;
  line-height: 70px;
  color: rgba(255, 255, 255, 0.6);
}

.navbar-custom .dropdown-toggle:after {
  content: initial;
}

.navbar-custom .menu-left {
  overflow: hidden;
}

.profile img {
  border: 2px solid #edf0f0;
  height: 36px;
  width: 36px;
}

/* Notification */
.notification-list {
  margin-left: 0 !important;
}

.notification-list .noti-title {
  margin-bottom: 0;
  width: auto;
  padding: 12px 20px;
}

.notification-list .noti-title h5 {
  margin: 0;
}

.notification-list .noti-title .label {
  float: right;
}

.noti-title:hover, .noti-title:focus, .noti-title:active {
    background-color: transparent;
}

.notification-list .noti-icon {
  font-size: 18px;
  padding: 0 12px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.8);
}

.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 8px;
}

.notification-list .notify-item {
  padding: 10px 20px;
}

.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #ffffff;
}

.notification-list .notify-item .notify-icon img {
  margin-top: 4px;
}

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-list .notify-item .notify-details b {
  font-weight: 500;
}

.notification-list .notify-item .notify-details small {
  display: block;
}

.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.notification-list .notify-all {
  border-radius: 0 0 0.25rem 0.25rem;
  margin: 0 0 -5px 0;
  background-color: #eeeeee;
}

.notification-list .profile-dropdown .notify-item {
  padding: 4px 20px;
}

.notification-list .notify-item-padding {
  padding: 10px 30px 10px 21px;
}

.film-room-notifications .notify-item-padding {
  padding-right: 30px !important;
}

.notification-list.film-room-notifications .notify-item .notify-details {
  margin-left: 0px !important;
  margin-top: -17px;
  padding-right: 20px;
}

.profile-dropdown {
  width: 170px;
}

.profile-dropdown i {
  font-size: 17px;
  vertical-align: middle;
  margin-right: 5px;
}

.profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  padding: 0 12px !important;
}

.nav-user img {
  height: 36px;
  width: 36px;
  object-fit: cover;
}

.navbar-nav {
  margin: 0;
}

.side-menu {
  bottom: 0;
  top: 0;
  width: 240px;
  z-index: 2;
}

.side-menu.left {
  background: #f7f7f7;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  top: 70px;
  position: fixed;
}

body.fixed-left .side-menu.left {
  bottom: 50px;
  height: 100%;
  margin-bottom: -70px;
  margin-top: 0;
  padding-bottom: 70px;
  position: fixed;
}

.content-page {
  min-height: 100vh;
  margin-left: 240px;
  overflow: hidden;
}

.content-page > .content {
  margin-bottom: 60px;
  margin-top: 70px;
  padding: 20px 5px 5px 5px;
}

.button-menu-mobile {
  border: none;
  color: #ffffff;
  display: inline-block;
  height: 70px;
  width: 60px;
  background-color: #000;
  font-size: 24px;
  z-index: 1;
}

.sidebar-inner {
  height: 100%;
}

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  padding-bottom: 30px;
  padding-top: 22px;
  width: 100%;
}

#sidebar-menu .nav > li > a .badge {
  position: absolute;
  right: 10px;
  top: 12px;
}

#sidebar-menu a {
  line-height: 1.3;
}

#sidebar-menu ul ul {
  display: none;
}

#sidebar-menu ul ul li {
  border-top: 0;
}

#sidebar-menu ul ul li.active a {
  color: #000;
  border-left: 3px solid $primary;
  background-color: #EDEDED;
}

#sidebar-menu ul ul a {
  color: #75798B;
  display: block;
  padding: 20px 20px 20px 65px;
  font-size: 0.9rem;
}

#sidebar-menu ul ul a:hover {
  color: #000;
}

#sidebar-menu ul ul a i {
  margin-right: 5px;
}

#sidebar-menu ul ul ul a {
  padding-left: 80px;
}

#sidebar-menu .label {
  margin-top: 2px;
}

#sidebar-menu .subdrop {
  color: #000;
}

#sidebar-menu > ul > li > a {
  color: #000;
  display: block;
  padding: 20px 20px;
  margin: 4px 0;
  border-left: 3px solid #ffffff;
}

#sidebar-menu > ul > li > a:hover {
  color: #000;
  text-decoration: none;
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu ul li .menu-arrow {
  -webkit-transition: -webkit-transform .15s;
  transition: -webkit-transform .15s;
  transition: transform .15s;
  transition: transform .15s, -webkit-transform .15s;
  position: absolute;
  right: 20px;
  display: inline-block;
  font-family: "Material Design Icons";
  text-rendering: auto;
  line-height: 24px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  color: #98a6ad;
}

#sidebar-menu ul li .menu-arrow:before {
  content: "\F142";
}

#sidebar-menu ul li a.subdrop .menu-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#sidebar-menu ul li a i {
  display: inline-block;
  font-size: 16px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

#sidebar-menu ul li a i.md {
  font-size: 18px;
}

#sidebar-menu > ul > li > a > i.i-right {
  float: right;
  margin: 3px 0 0 0;
}

#sidebar-menu > ul > li > a.active {
  background: #EDEDED !important;
  border-left: 3px solid $primary;
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
}

.page-title {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 40px;
  float: left;
}

.page-title-box {
  background: #ffffff;
  padding: 40px 40px 20px;
  margin: -20px -20px 22px -20px;
}

.page-title-box .breadcrumb {
  margin: 0;
  padding: 0;
}

/* Small Menu */
.side-menu-sm {
  width: 180px;
  text-align: center;
}

.side-menu-sm #sidebar-menu > ul > li > a > i {
  display: block;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  margin: 0;
}

.side-menu-sm #sidebar-menu ul ul a {
  padding: 10px 20px 10px 20px;
}

.side-menu-sm + .content-page .footer {
  left: 180px;
}

#wrapper.enlarged .side-menu-sm {
  text-align: left;
}

#wrapper.enlarged .side-menu-sm #sidebar-menu ul li a i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 15px;
  vertical-align: middle;
  width: 20px;
}

.side-menu-sm + .content-page {
  margin-left: 180px;
}

/* Header 2 */
.page-header-2 {
  background: #ffffff;
  border-bottom: 1px solid #dee2e8;
  margin: -20px -15px 22px -15px;
  padding: 10px 20px 0 20px;
}

#wrapper.enlarged .navbar-custom {
  margin-left: 70px;
}

#wrapper.enlarged .menu-title, #wrapper.enlarged .menu-arrow {
  display: none !important;
}

#wrapper.enlarged #sidebar-menu ul ul {
  border: 2px solid #eeeeee;
  margin-top: -5px;
  padding-top: 5px;
  z-index: 9999;
  background-color: #ffffff;
}

#wrapper.enlarged .left.side-menu {
  width: 70px;
  z-index: 1000;
}

#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 52px;
}

#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a:hover {
  background: #eeeeee !important;
}

#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a:active {
  background: #eeeeee !important;
}

#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a:focus {
  background: #eeeeee !important;
}

#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a i {
  margin-right: 20px !important;
  font-size: 20px;
}

#wrapper.enlarged .left.side-menu .label {
  position: absolute;
  top: 5px;
  left: 35px;
  text-indent: 0;
  display: block !important;
  padding: .2em .6em .3em !important;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li {
  position: relative;
  white-space: nowrap;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a {
  position: relative;
  width: 260px;
  background: #eeeeee;
  color: #000;
  border-color: $primary;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul a {
  background: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding-left: 15px;
  position: relative;
  width: 186px;
  z-index: 6;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul a:hover {
  color: #000;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover a span {
  display: inline;
}

#wrapper.enlarged .left.side-menu #sidebar-menu li .show-menu + ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
}

#wrapper.enlarged .left.side-menu #sidebar-menu li .show-menu + ul a {
  background: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding-left: 15px;
  position: relative;
  width: 186px;
  z-index: 6;
}

#wrapper.enlarged .left.side-menu #sidebar-menu li .show-menu + ul a:hover {
  color: $primary;
}

#wrapper.enlarged .left.side-menu #sidebar-menu a.subdrop {
  color: $primary !important;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li > ul {
  display: none;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul ul li:hover > ul {
  display: block;
  left: 186px;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul ul li > a span.pull-right {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul ul li.active a {
  color: $primary;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li > a span {
  display: none;
  padding-left: 10px;
}

#wrapper.enlarged .left.side-menu #sidebar-menu ul > li > a span.badge {
  padding-left: 5px;
}

#wrapper.enlarged .left.side-menu .user-details {
  display: none;
}

#wrapper.enlarged .content-page {
  margin-left: 70px;
}

#wrapper.enlarged .footer {
  left: 70px;
}

#wrapper.enlarged .topbar .topbar-left {
  width: 70px !important;
}

#wrapper.enlarged .topbar .topbar-left .logo span {
  display: none;
  opacity: 0;
}

#wrapper.enlarged .topbar .topbar-left .logo .icon-c-logo {
  display: block;
  line-height: 70px;
}

#wrapper.enlarged #sidebar-menu > ul > li:hover > a.open :after {
  display: none;
}

#wrapper.enlarged #sidebar-menu > ul > li:hover > a.active :after {
  display: none;
}

#wrapper.enlarged .tips-box {
  display: none;
}

.tips-box .portlet {
  -webkit-box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.05);
}

.user-details {
  padding: 20px;
  padding-bottom: 0;
  position: relative;
}

.user-details img {
  position: relative;
  z-index: 9999;
}

.user-details .user-info {
  color: #444444;
  margin-left: 60px;
  position: relative;
  z-index: 99999;
}

.user-details .user-info a.dropdown-toggle {
  color: #797979;
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding-top: 5px;
}

.orgs-view-box {
    min-width: 350px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.open-button {
    background-color: white;
    border: 0.25px solid #707070;
    max-width: 200px;
    margin-left: 150px;
    padding: 10px 0px;
}

.open-button-svg {
    transform: rotate(90);
    position: absolute;
    pointer-events: none;
}

.orgs-view-options {
    display: flex;
    border-bottom: 0.25px solid #eee;
    padding: 10px 5px;
}

.orgs-view-options-box {
    max-height: 435px;
    overflow-y: scroll;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 55px;
    scrollbar-width: thin;
    scrollbar-color: #DCDCDC;
    z-index: 1;
    position: absolute;
    background-color: white;
    min-width: 350px;
}

.orgs-view-options-box::-webkit-scrollbar {
    width: 10px;
    height: 40px;
}

.org-logo {
    height: 35px;
    width: 35px;
    border: none;
    flex: 1;
}

.name-of-org {
    flex: 3;
    margin-bottom: 0px;
    margin-top: 3px;
}

.view-date {
    flex: 2;
    margin-bottom: 0px;
    text-align: right;
    margin-top: 3px;
}

#wrapper.right-bar-enabled .right-bar {
  right: 0;
}

#wrapper.right-bar-enabled .left-layout {
  left: 0;
}

/* Right sidebar */
.side-bar.right-bar {
  float: right !important;
  right: -266px;
  top: 70px;
  bottom: 0;
  font-size: 0.9rem;
}

.side-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
  display: block;
  float: left;
  overflow: auto;
  position: fixed;
  transition: all 200ms ease-out;
  width: 240px;
}

.right-bar {
  background: rgb(26,26,26) !important;
  z-index: 99 !important;
}

.right-bar h4 {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  font-size: 1.2rem;
}

.right-bar .nav-link {
  font-size: 16px;
}

.right-bar .tab-content {
  padding: 20px;
}

.contact-list {
  max-height: 600px;
}

.contact-list .list-group-item {
  border: none;
}

.contact-list .list-group-item:hover {
  background: #f5f5f5;
}

.contact-list .list-group-item a {
  display: block;
  width: 100%;
}

.contact-list i.offline {
  color: #ED6163 !important;
}

.contact-list i.away {
  color: #DEDA6A;
}

.contacts-list .avatar {
  float: left;
  margin-right: 5px;
  width: 30px;
}

.contacts-list .avatar img {
  border-radius: 50%;
  width: 100%;
}

.contacts-list .list-group-item span.name {
  color: #707780;
  float: left;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
}

.contacts-list i {
  color: #dddddd;
  float: right;
  font-size: 9px;
  line-height: 30px;
}

.contacts-list i.online {
  color: #a0d269;
}

.contacts-list i.offline {
  color: #ED6163 !important;
}

.contacts-list i.away {
  color: #DEDA6A;
}

/* Seach */
.app-search {
  position: relative;
  padding-top: 18px;
}

.app-search a {
  position: absolute;
  top: 18px;
  left: 220px;
  display: block;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.app-search a:hover {
  color: #ffffff;
}

.app-search .form-control,
.app-search .form-control:focus {
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 13px;
  height: 34px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 40px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 1px;
  width: 200px;
}

.app-search input::-webkit-input-placeholder {
  color: white;
}

.app-search input:-moz-placeholder {
  color: white;
}

.app-search input::-moz-placeholder {
  color: white;
}

.app-search input:-ms-input-placeholder {
  color: white;
}

.app-search .school-name {
    right: 20px;
    position: absolute;
    top: 50%;
}

.app-search .school-name h4 {
    color: #fff;
    font-family: "KairosSans";
    margin: 0;
    text-transform: uppercase;
}

/* ===========
   Common
 =============*/
body {
  background: #fbfbfb;
  margin: 0;
  color: #4c5667;
  overflow-x: hidden !important;
  font-size: 0.95rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  background: #fbfbfb;
}

* {
  outline: none !important;
}

a {
  color: #000;
}

a:hover, a:active, a:focus {
  outline: 0;
  text-decoration: none;
  color: #555;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.footer {
  background-color: #ffffff;
  bottom: 0;
  color: #98a6ad;
  padding: 20px 30px;
  position: absolute;
  right: 0;
  left: 240px;
  text-align: center;
}

#wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.page {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.card-box {
  padding: 20px;
  box-shadow: 0 1px 15px 0px rgba(0,0,0,0.1);
  border-radius: 2px;
  -moz-border-radius: 2px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.header-title {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 8px;
  margin-top: 0;
}

.social-links li a {
  -webkit-border-radius: 50%;
  background: #EFF0F4;
  border-radius: 50%;
  color: #7A7676;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

/* ===========
   Bootstrap-custom
 =============*/
.h1, h1 {
  font-size: 36px;
}

.h2, h2 {
  font-size: 30px;
}

.h3, h3 {
  font-size: 24px;
}

.h4, h4 {
  font-size: 18px;
}

.h5, h5 {
  font-size: 14px;
}

.h6, h6 {
  font-size: 12px;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto,
.col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7,
.col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3,
.col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10,
.col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
.col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5,
.col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 10px;
  padding-right: 10px;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 5px;
}

.dropdown-menu {
  padding: 4px 0;
  border: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.dropdown-menu > li > a {
  padding: 6px 20px;
}

.dropdown-menu .dropdown-item {
  width: auto !important;
  font-size: 0.9rem;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #f2f2f2;
  color: inherit;
}

.dropdown-menu-lg {
  max-width: 280px;
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}

code {
  color: #5d9cec;
  border-radius: 4px;
}

code, pre {
  background-color: #f4f8fb;
}

.blockquote {
  font-size: 1rem;
}

/* Background Colors */
.bg-empty {
  background: transparent !important;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: #39BF6C !important;
}

.bg-info {
  background-color: #57BCFA !important;
}

.bg-warning {
  background-color: #DEDA6A !important;
}

.bg-danger {
  background-color: #ED6163 !important;
}

.bg-muted {
  background-color: #F5F5F5 !important;
}

.bg-inverse {
  background-color: #4c5667 !important;
}

.bg-purple {
  background-color: #7266ba !important;
}

.bg-pink {
  background-color: #f76397 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

/* Text Colors */
.text-white {
  color: #ffffff;
}

.text-danger {
  color: #ED6163 !important;
}

.text-muted {
  color: #98a6ad !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: #DEDA6A !important;
}

.text-success {
  color: #39BF6C !important;
}

.text-info {
  color: #57BCFA !important;
}

.text-inverse {
  color: #4c5667 !important;
}

.text-pink {
  color: #f76397 !important;
}

.text-purple {
  color: #7266ba !important;
}

.text-dark {
  color: #797979 !important;
}

/* Dropcap */
.dropcap {
  font-size: 3.1em;
}

.dropcap, .dropcap-circle, .dropcap-square {
  display: block;
  float: left;
  font-weight: 400;
  line-height: 36px;
  margin-right: 6px;
  text-shadow: none;
}

/* ===========
   Helper classes
 =============*/
.p-0 {
  padding: 0 !important;
}

.p-20 {
  padding: 20px;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.m-h-50 {
  min-height: 50px;
}

.l-h-34 {
  line-height: 34px !important;
}

.font-light {
  font-weight: 300;
}

.font-weight-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.wrapper-md {
  padding: 20px;
}

.pull-in {
  margin-left: -20px;
  margin-right: -20px;
}

.b-0 {
  border: none !important;
}

.no-border {
  border: none;
}

.center-page {
  float: none !important;
  margin: 0 auto;
}

.bx-s-0 {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.bx-shadow {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.mx-box {
  max-height: 380px;
  min-height: 380px;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

/* ===========
   Waves Effect
 =============*/
/*!
 * Waves v0.7.6
 * http://fian.my.id/Waves
 */
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0) translate(0, 0);
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.waves-block {
  display: block;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-primary .waves-ripple {
  background-color: rgba(231, 248, 22, 0.75);
}

/* =============
   Animation
============= */
/* Bounce 1 */
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

/* Bounce 2 */
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

/* Dropdown */
@-webkit-keyframes dropdownOpen {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

/* Portlets loader */
@-webkit-keyframes loaderAnimate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loaderAnimate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(220deg);
            transform: rotate(220deg);
  }
}

@-webkit-keyframes loaderAnimate2 {
  0% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(-140deg);
  }
  50% {
    -webkit-box-shadow: inset #555 0 0 0 2px;
            box-shadow: inset #555 0 0 0 2px;
  }
  100% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loaderAnimate2 {
  0% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(-140deg);
    transform: rotate(-140deg);
  }
  50% {
    -webkit-box-shadow: inset #555 0 0 0 2px;
            box-shadow: inset #555 0 0 0 2px;
  }
  100% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
  }
}

@keyframes loaderAnimate2 {
  0% {
    -webkit-box-shadow: inset #999 0 0 0 17px;
            box-shadow: inset #999 0 0 0 17px;
    -webkit-transform: rotate(-140deg);
            transform: rotate(-140deg);
  }
  50% {
    -webkit-box-shadow: inset #999 0 0 0 2px;
            box-shadow: inset #999 0 0 0 2px;
  }
  100% {
    -webkit-box-shadow: inset #999 0 0 0 17px;
            box-shadow: inset #999 0 0 0 17px;
    -webkit-transform: rotate(140deg);
            transform: rotate(140deg);
  }
}

/* Portlets loader */
@-webkit-keyframes loaderAnimate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loaderAnimate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(220deg);
            transform: rotate(220deg);
  }
}

@-webkit-keyframes loaderAnimate2 {
  0% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(-140deg);
  }
  50% {
    -webkit-box-shadow: inset #555 0 0 0 2px;
            box-shadow: inset #555 0 0 0 2px;
  }
  100% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loaderAnimate2 {
  0% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(-140deg);
    transform: rotate(-140deg);
  }
  50% {
    -webkit-box-shadow: inset #555 0 0 0 2px;
            box-shadow: inset #555 0 0 0 2px;
  }
  100% {
    -webkit-box-shadow: inset #555 0 0 0 8px;
            box-shadow: inset #555 0 0 0 8px;
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
  }
}

@keyframes loaderAnimate2 {
  0% {
    -webkit-box-shadow: inset #999 0 0 0 17px;
            box-shadow: inset #999 0 0 0 17px;
    -webkit-transform: rotate(-140deg);
            transform: rotate(-140deg);
  }
  50% {
    -webkit-box-shadow: inset #999 0 0 0 2px;
            box-shadow: inset #999 0 0 0 2px;
  }
  100% {
    -webkit-box-shadow: inset #999 0 0 0 17px;
            box-shadow: inset #999 0 0 0 17px;
    -webkit-transform: rotate(140deg);
            transform: rotate(140deg);
  }
}

/* ===========
   Badge
 =============*/
.badge {
  font-weight: 600;
  padding: 3px 5px;
  font-size: 12px;
  margin-top: 1px;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs, .badge-sm {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-primary {
  background-color: $primary;
}

.badge-success {
  background-color: #39BF6C;
}

.badge-info {
  background-color: #57BCFA;
}

.badge-warning {
  background-color: #DEDA6A;
  color: #ffffff;
}

.badge-danger {
  background-color: #ED6163;
}

.badge-purple {
  background-color: #7266ba;
  color: #ffffff;
}

.badge-pink {
  background-color: #f76397;
  color: #ffffff;
}

.badge-inverse {
  background-color: #4c5667;
  color: #ffffff;
}

/* ===========
   Pagination
 =============*/
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.pagination > li > a,
.pagination > li > span {
  color: #636e7b;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #e4e7ea;
}

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  border-radius: 3px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}

.pager .disabled > a, .pager .disabled > a:focus, .pager .disabled > a:hover, .pager .disabled > span {
  opacity: 0.6;
}

.pager li > a, .pager li > span {
  border-radius: 3px;
  color: #4c5667;
}

/* Demo Only */
.icon-list-demo div {
  cursor: pointer;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #75798B;
  font-size: 14px;
}

.icon-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}

.icon-list-demo i {
  -webkit-transition: all 0.2s;
  display: inline-block;
  font-size: 20px;
  margin: 0;
  text-align: center;
  transition: all 0.2s;
  vertical-align: middle;
  width: 40px;
}

.icon-list-demo .col-md-4:hover {
  color: $primary;
}

.icon-list-demo .col-md-4:hover i {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.ionicon-list i {
  font-size: 16px;
}

.ionicon-list .col-md-3:hover i {
  -webkit-transform: scale(2);
  transform: scale(2);
}

.button-list {
  margin-left: -8px;
  margin-bottom: -12px;
}

.button-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

/* =============
   Print css
============= */
@media print {
  .logo, .breadcrumb, .page-title, .footer, .topbar, .btn-group.pull-right.m-t-15 {
    display: none;
    margin: 0;
    padding: 0;
  }
  .left, .right-bar {
    display: none;
  }
  .content, .card-box {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
  .content-page {
    margin-left: 0;
    margin-top: 0 !important;
  }
}

/* ===========
   Buttons
 =============*/
.btn-primary, .btn-info, .btn-warning,
.btn-danger, .btn-dark {
    color: #fff;
    height: 38px;
    padding: 0.5rem 0.75rem;
}

.btn-primary {
  background-color: $primary;
  border: 1px solid $primary;
  color: #000;
}

// Commenting this out until we get further direction.
// .btn-primary:focus {
//     background-color: $primary;
//     box-shadow: 0 0 0 5px #d7e80b !important;
//     outline: 3px solid #000 !important;
//     color: #000;
// }

.btn-primary:active {
    background-color: $primary;
    border: 2px solid black;
    color: #000;
}

.btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary, .btn-primary:not([disabled]):not(.disabled).active, .btn-primary:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: #d7e80b;
    border-color: #d7e80b;
    box-shadow: none !important;
    color: #000;
}

.btn-primary.focus, .btn-outline-primary.focus, .btn-outline-primary:focus,
.btn-primary:not([disabled]):not(.disabled).active, .btn-primary:not([disabled]):not(.disabled):active, .show > .btn-primary.dropdown-toggle,
.btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(249, 205, 72, 0.5);
          box-shadow: 0 0 0 2px rgba(249, 205, 72, 0.5);
}

.btn-light {
  border-color: #eeeeee;
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active,
.btn-light.focus, .btn-light:active, .btn-light:focus, .btn-light:hover,
.open > .dropdown-toggle.btn-light {
  border-color: #bfbfbf;
}

.btn-light.focus, .btn-light:focus, .btn-outline-light.focus, .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 2px #d9d9d9;
          box-shadow: 0 0 0 2px #d9d9d9;
}

.btn-success {
  color: #000 !important;
  background-color: $primary;
  border: 1px solid $primary;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover, .open > .dropdown-toggle.btn-success,
.btn-success:not([disabled]):not(.disabled).active, .btn-success:not([disabled]):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  background-color: #32ad61;
  border-color: #32ad61;
}

.btn-success.focus, .btn-success:focus, .btn-outline-success.focus, .btn-outline-success:focus,
.btn-success:not([disabled]):not(.disabled).active, .btn-success:not([disabled]):not(.disabled):active, .show > .btn-success.dropdown-toggle,
.btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 177, 157, 0.5);
          box-shadow: 0 0 0 2px rgba(0, 177, 157, 0.5);
}

.btn-info {
  background-color: #57BCFA;
  border: 1px solid #57BCFA;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover,
.open > .dropdown-toggle.btn-info, .btn-info:not([disabled]):not(.disabled).active, .btn-info:not([disabled]):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: #50b1ed;
  border-color: #50b1ed;
}

.btn-info.focus, .btn-info:focus, .btn-outline-info.focus, .btn-outline-info:focus,
.btn-info:not([disabled]):not(.disabled).active, .btn-info:not([disabled]):not(.disabled):active, .show > .btn-info.dropdown-toggle,
.btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(61, 220, 247, 0.5);
          box-shadow: 0 0 0 2px rgba(61, 220, 247, 0.5);
}

.btn-warning {
  background-color: #DEDA6A;
  border: 1px solid #DEDA6A;
  color: #ffffff;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus, .btn-warning:active,
.btn-warning:focus, .btn-warning:hover, .open > .dropdown-toggle.btn-warning, .btn-warning:not([disabled]):not(.disabled).active,
.btn-warning:not([disabled]):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  background-color: #d1cd60;
  border-color: #d1cd60;
  color: #ffffff;
}

.btn-warning.focus, .btn-warning:focus, .btn-outline-warning.focus, .btn-outline-warning:focus,
.btn-warning:not([disabled]):not(.disabled).active, .btn-warning:not([disabled]):not(.disabled):active, .show > .btn-warning.dropdown-toggle,
.btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(59, 175, 218, 0.5);
          box-shadow: 0 0 0 2px rgba(59, 175, 218, 0.5);
}

.btn-danger {
  background-color: #ED6163;
  color: #fff;
  border: 1px solid #ED6163;
}

.btn-danger:active, .btn-danger:focus, .btn-danger:hover, .btn-danger.active, .btn-danger.focus, .btn-danger:active, .btn-danger:focus,
.btn-danger:hover, .open > .dropdown-toggle.btn-danger, .btn-danger:not([disabled]):not(.disabled).active, .btn-danger:not([disabled]):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background-color: #dd5658;
  border-color: #dd5658;
}

.btn-danger.focus, .btn-danger:focus, .btn-outline-danger.focus, .btn-outline-danger:focus,
.btn-danger:not([disabled]):not(.disabled).active, .btn-danger:not([disabled]):not(.disabled):active, .show > .btn-danger.dropdown-toggle,
.btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(239, 83, 80, 0.5);
          box-shadow: 0 0 0 2px rgba(239, 83, 80, 0.5);
}

.btn-dark {
  background-color: #000;
  border: 1px solid #000;
}

// Commenting this out until we get further direction.
// .btn-dark:focus {
//     background-color: #000;
//     box-shadow: 0 0 0 5px #fff !important;
//     outline: 3px solid #000 !important;
// }

.btn-dark:hover, .btn-dark:active, .btn-dark.active, .btn-dark.focus, .btn-dark:active, .btn-dark:focus, .btn-dark:hover, .open > .dropdown-toggle.btn-dark {
  background-color: #202532;
  border-color: #202532;
}

.btn-dark.focus, .btn-dark:focus, .btn-outline-dark.focus, .btn-outline-dark:focus,
.btn-dark:not([disabled]):not(.disabled).active, .btn-dark:not([disabled]):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  -webkit-box-shadow: 0 0 0 2px rgba(42, 49, 66, 0.5);
          box-shadow: 0 0 0 2px rgba(42, 49, 66, 0.5);
}

.btn-link {
  color: #000;
}

.btn-link:hover {
  color: $primary;
}

/* button Outline */
.btn-outline-primary {
  color: $primary;
  border-color: $primary;
}

.btn-outline-primary:hover {
  background-color: $primary;
  border-color: $primary;
}

.btn-outline-success {
  color: #39BF6C;
  border-color: #39BF6C;
}

.btn-outline-success:hover {
  background-color: #39BF6C;
  border-color: #39BF6C;
}

.btn-outline-info {
  color: #57BCFA;
  border-color: #57BCFA;
}

.btn-outline-info:hover {
  background-color: #57BCFA;
  border-color: #57BCFA;
}

.btn-outline-warning {
  color: #DEDA6A;
  border-color: #DEDA6A;
}

.btn-outline-warning:hover {
  background-color: #DEDA6A;
  border-color: #DEDA6A;
  color: #ffffff;
}

.btn-outline-danger {
  color: #ED6163;
  border-color: #ED6163;
}

.btn-outline-danger:hover {
  background-color: #ED6163;
  border-color: #ED6163;
}

.btn-outline-dark {
  color: #000;
  border-color: #000;
}

.btn-outline-dark:hover {
  background-color: #000;
  border-color: #000;
}

.btn-custom {
  border-bottom: 3px solid transparent;
}

.btn-custom.btn-default {
  background-color: #f3f3f3;
  border-bottom: 2px solid #cccccc !important;
}

.btn-custom.btn-primary {
  border-bottom: 2px solid #f7c017 !important;
}

.btn-custom.btn-success {
  border-bottom: 2px solid #007e70 !important;
}

.btn-custom.btn-info {
  border-bottom: 2px solid #08aac6 !important;
}

.btn-custom.btn-warning {
  border-bottom: 2px solid #2494be !important;
}

.btn-custom.btn-danger {
  border-bottom: 2px solid #c71612 !important;
}

.btn-custom.btn-dark {
  border-bottom: 2px solid #0b0c0f !important;
}

.btn-rounded {
  border-radius: 2em;
  padding: 6px 18px;
}

.fileupload {
  overflow: hidden;
  position: relative;
}

.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* ===========
   Button Border Radius Removal
 =============*/

/*Organization Edit Page*/
.infiput .btn {
    border-radius: 0 !important;
}
.card-container .btn {
    border-radius: 0 !important;
}
/*Super Admin Tab buttons*/
#app .btn {
    border-radius: 0 !important;
}

/*Player Modal Popup buttons*/
.modal-body .btn {
    border-radius: 0 !important;
}
.modal-footer .btn {
    border-radius: 0 !important;
}

/*Search Recruits Warn Popup buttons*/
.swal2-actions .btn {
    border-radius: 0 !important;
}

/*Pagination Prev/Next buttons*/
.bottom .page-link {
    border-radius: 0 !important;
}
.pagination .page-item .page-link {
    border-radius: 0 !important;
}

#cropper-modal {
  .modal-footer {
    border-top: none;
    padding-top: 0;
    .centered {
      margin: 0 auto;
      button {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

#cropper-modal {
  .modal-header {
    border-bottom: none;
    .modal-title.centered {
      text-align: center;
    }
  }
}

/* ===========
   Portlets
 =============*/
.portlet {
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.4s;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: all 0.4s;
}

.portlet .portlet-heading {
  border-radius: 3px;
  color: #ffffff;
  padding: 12px 20px;
}

.portlet .portlet-heading .portlet-title {
  color: #ffffff;
  float: left;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 6px;
  letter-spacing: 0.03em;
}

.portlet .portlet-heading .portlet-widgets {
  display: inline-block;
  float: right;
  font-size: 15px;
  line-height: 30px;
  padding-left: 15px;
  position: relative;
  text-align: right;
}

.portlet .portlet-heading .portlet-widgets .divider {
  margin: 0 5px;
}

.portlet .portlet-heading a {
  color: #999999;
}

.portlet .portlet-body {
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px;
}

.portlet-default .portlet-title {
  color: #797979 !important;
}

.portlet .portlet-heading .portlet-widgets .collapsed .ion-minus-round:before {
  content: "\f217" !important;
}

.portlet .portlet-heading.bg-purple a, .portlet .portlet-heading.bg-info a, .portlet .portlet-heading.bg-success a, .portlet .portlet-heading.bg-primary a, .portlet .portlet-heading.bg-danger a, .portlet .portlet-heading.bg-warning a, .portlet .portlet-heading.bg-inverse a, .portlet .portlet-heading.bg-pink a {
  color: #ffffff;
}

.panel-disabled {
  background: rgba(243, 242, 241, 0.5);
  cursor: progress;
  bottom: 15px;
  left: 0;
  position: absolute;
  right: -5px;
  top: 0;
}

.loader-1 {
  -webkit-animation: loaderAnimate 1000ms linear infinite;
  animation: loaderAnimate 1000ms linear infinite;
  clip: rect(0, 30px, 30px, 15px);
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 30px;
}

.loader-1:after {
  -webkit-animation: loaderAnimate2 1000ms ease-in-out infinite;
  animation: loaderAnimate2 1000ms ease-in-out infinite;
  border-radius: 50%;
  clip: rect(0, 30px, 30px, 15px);
  content: '';
  height: 30px;
  position: absolute;
  width: 30px;
}

/* =============
   Checkbox and Radios
============= */
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
}

.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-weight: 500;
  font-size: 13px;
}

.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -17px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}

.checkbox label::after {
  color: #333333;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -17px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 0;
  width: 16px;
}

.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single input {
  height: 18px;
  width: 18px;
  position: absolute;
}

.checkbox.checkbox-single label {
  height: 18px;
  width: 18px;
}

.checkbox.checkbox-single label:before {
  margin-left: 0;
}

.checkbox.checkbox-single label:after {
  margin-left: 0;
}

.checkbox-custom input[type="checkbox"]:checked + label::before {
  background-color: $primary;
  border-color: $primary;
}

.checkbox-custom input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: $primary;
  border-color: $primary;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #000;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #ED6163;
  border-color: #ED6163;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #57BCFA;
  border-color: #57BCFA;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #DEDA6A;
  border-color: #DEDA6A;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #39BF6C;
  border-color: #39BF6C;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #7266ba;
  border-color: #7266ba;
}

.checkbox-purple input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-pink input[type="checkbox"]:checked + label::before {
  background-color: #f76397;
  border-color: #f76397;
}

.checkbox-pink input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #4c5667;
  border-color: #4c5667;
}

.checkbox-inverse input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

/* Radios */
.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-weight: 500;
  font-size: 13px;
}

.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
  outline: none !important;
}

.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #333333;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 11px;
  left: 3px;
  margin-left: -20px;
  position: absolute;
  top: 3px;
  transform: scale(0, 0);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 11px;
}

.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: thin dotted;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-custom input[type="radio"] + label::after {
  background-color: $primary;
}

.radio-custom input[type="radio"]:checked + label::before {
  border-color: $primary;
}

.radio-custom input[type="radio"]:checked + label::after {
  background-color: $primary;
}

.radio-primary input[type="radio"] + label::after {
  background-color: $primary;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: $primary;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: $primary;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #ED6163;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #ED6163;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #ED6163;
}

.radio-info input[type="radio"] + label::after {
  background-color: #57BCFA;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #57BCFA;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #57BCFA;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #DEDA6A;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #DEDA6A;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #DEDA6A;
}

.radio-success input[type="radio"] + label::after {
  background-color: #39BF6C;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #39BF6C;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #39BF6C;
}

.radio-purple input[type="radio"] + label::after {
  background-color: #7266ba;
}

.radio-purple input[type="radio"]:checked + label::before {
  border-color: #7266ba;
}

.radio-purple input[type="radio"]:checked + label::after {
  background-color: #7266ba;
}

.radio-pink input[type="radio"] + label::after {
  background-color: #f76397;
}

.radio-pink input[type="radio"]:checked + label::before {
  border-color: #f76397;
}

.radio-pink input[type="radio"]:checked + label::after {
  background-color: #f76397;
}

/* =============
   Tabs
============= */
.tab-content {
  padding: 20px 0 0 0;
}

.nav-tabs > li > a, .nav-pills > li > a {
  color: #000;
  font-weight: 600;
}

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: $primary;
}

/* Vertial tab */
.tabs-vertical-env .tab-content {
  background: #ffffff;
  display: table-cell;
  padding: 0 0 0 20px;
  margin-bottom: 0;
  vertical-align: top;
}

.tabs-vertical-env .nav.tabs-vertical {
  display: table-cell;
  min-width: 120px;
  vertical-align: top;
  width: 150px;
}

.tabs-vertical-env .nav.tabs-vertical li > a {
  color: #000;
  white-space: nowrap;
  font-weight: 600;
  border-radius: 2px;
}

.tabs-vertical-env .nav.tabs-vertical li > a.active {
  background-color: $primary;
  border: 0;
  color: #ffffff;
}

.tabs-vertical-env-right .tab-content {
  padding: 0 20px 0 0;
}

.tabs-bordered {
	flex: 0;
	flex-direction: row;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2) !important;
}

.tabs-bordered .nav-item {
	flex-grow: 1;
  margin-bottom: -2px;
}

.tabs-bordered li a, .tabs-bordered li a:hover, .tabs-bordered li a:focus {
  border: 0 !important;
  padding: 10px 20px !important;
}

.tabs-bordered li a.active {
  border-bottom: 2px solid $primary !important;
}

/* Navpills */
.nav-pills > li > a {
  color: #000;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: $primary;
}

/*===========
Modal
=========== */
.modal .modal-dialog .close {
  top: 0;
  position: absolute;
  right: 0;
  height: 36px;
  width: 36px;
  background-color: #000;
  opacity: 1;
  border: 2px solid #ffffff;
  text-shadow: none;
  color: #ffffff;
  border-radius: 50%;
  padding: 0;
  font-size: 18px;
}

.modal .modal-dialog .modal-title {
  margin: 0;
}

.modal .modal-dialog .modal-content {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #DDDDDD;
  border-radius: 2px;
  box-shadow: none;

  &.modal-border-themed {
    border-color: $primary;
  }
}

.modal .modal-dialog .modal-content .modal-header {
  border-bottom-width: 2px;
  margin: 0;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 20px;
}

.modal-full {
  width: 98%;
  max-width: 100%;
}

/* Custom-modal */
.modal-demo {
  background-color: #ffffff;
  width: 600px;
  border-radius: 4px;
  display: none;
}

.modal-demo .close {
  position: absolute;
  top: 12px;
  right: 25px;
  color: #ffffff;
  opacity: 0.5;
  font-weight: normal;
  font-size: 26px;
}

.modal-demo .close:hover {
  opacity: 1;
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: $primary;
  color: #ffffff;
  text-align: left;
  margin: 0;
}

.custom-modal-text {
  padding: 20px;
}

.custombox-modal-flash .close, .custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999;
}

/* =============
   Progressbars
============= */
.progress {
  -webkit-box-shadow: none !important;
  background-color: #f3f3f3;
  box-shadow: none !important;
  height: 10px;
  margin-bottom: 18px;
  overflow: hidden;
}

.progress-bar {
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  background-color: $primary;
  line-height: 12px;
}

.progress.progress-sm {
  height: 5px !important;
}

.progress.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}

.progress.progress-md {
  height: 15px !important;
}

.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}

/* =============
   Tooltips & Popovers
============= */
/* Tooltips */
.tooltip-inner {
  border-radius: 1px;
  padding: 6px 10px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
}

/* Popover */
.popover {
  font-family: inherit;
}

.popover .popover-title {
  background-color: transparent;
  color: $primary;
  margin: 0;
  font-weight: 600;
}

/* =============
   Alerts
============= */
.alert-success {
  background-color: #e4fffc !important;
  border-color: #00e4ca !important;
  color: #39BF6C;
}

.alert-success .alert-link {
  color: #39BF6C;
}

.alert-info {
  background-color: #d0f7fd !important;
  border-color: #6ee5f9 !important;
  color: #57BCFA;
}

.alert-info .alert-link {
  color: #57BCFA;
}

.alert-warning {
  background-color: #fcfefe !important;
  border-color: #66c1e2 !important;
  color: #DEDA6A;
}

.alert-warning .alert-link {
  color: #DEDA6A;
}

.alert-danger {
  background-color: #fef4f4 !important;
  border-color: #f3817f !important;
  color: #ED6163;
}

.alert-danger .alert-link {
  color: #ED6163;
}



/* =============
   Carousel
============= */
.carousel-control {
  width: 10%;
}

.carousel-control span {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  font-size: 30px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* ===========
   Widgets
 =============*/
/* Inbox widget */
.inbox-widget .inbox-item {
  border-bottom: 1px solid #fafafa;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}

.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}

.inbox-widget .inbox-item img {
  width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
  color: #000;
  display: block;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-text {
  color: #98a6ad;
  display: block;
  font-size: 12px;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-date {
  color: #98a6ad;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}

/* Chat widget */
.conversation-list {
  list-style: none;
  max-height: 330px;
  padding: 0 20px;
}

.conversation-list li {
  margin-bottom: 24px;
}

.conversation-list .chat-avatar {
  display: inline-block;
  float: left;
  text-align: center;
  width: 40px;
}

.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}

.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}

.conversation-list .ctext-wrap {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background: #f5f5f5;
  border-radius: 3px;
  display: inline-block;
  padding: 10px;
  position: relative;
}

.conversation-list .ctext-wrap i {
  color: #000;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}

.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}

.conversation-list .ctext-wrap:after {
  right: 100%;
  top: 20%;
  border: 5px solid rgba(213, 242, 239, 0);
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #f5f5f5;
  margin-top: -5px;
}

.conversation-list .conversation-text {
  display: inline-block;
  float: left;
  font-size: 12px;
  margin-left: 12px;
  width: 70%;
}

.conversation-list .odd .chat-avatar {
  float: right !important;
}

.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}

.conversation-list .odd p {
  color: #f2f2f2;
}

.conversation-list .odd .ctext-wrap {
  background: $primary !important;
}

.conversation-list .odd .ctext-wrap i {
  color: #ffffff;
}

.conversation-list .odd .ctext-wrap:after {
  border-color: rgba(238, 238, 242, 0) !important;
  border-left-color: $primary !important;
  left: 100% !important;
  top: 20% !important;
}

.chat-send {
  padding-left: 0px;
  padding-right: 30px;
}

.chat-send button {
  width: 100%;
}

.chat-inputbar {
  padding-left: 30px;
}

#todo-message {
  font-size: 16px;
}

.todo-list li {
  border: 0px;
  margin: 0px;
  padding: 5px !important;
  background: transparent !important;
  display: block;
}

.todo-send {
  padding-left: 0px;
}

/* Widget-chart */
.widget-chart ul li {
  width: 31.5%;
  display: inline-block;
  padding: 0px;
}

.widget-panel {
  padding: 30px 20px;
  padding-left: 30px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
}

.widget-panel i {
  font-size: 60px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  line-height: 60px;
}

.widget-user {
  min-height: 112px;
}

.widget-user img {
  height: 72px;
  float: left;
}

.widget-user .wid-u-info {
  margin-left: 90px;
}

.widget-user .wid-u-info p {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-simple-chart .circliful-chart {
  float: left;
  margin-top: -5px;
}

.widget-icon i {
  float: left;
  font-size: 48px;
}

.widget-icon .wid-icon-info {
  margin-left: 80px;
}

/* Widget (background-icon) */
.widget-bg-color-icon .bg-icon {
  height: 80px;
  width: 80px;
  text-align: center;
  border-radius: 50%;
}

.widget-bg-color-icon .bg-icon i {
  font-size: 32px;
  color: #ffffff !important;
  line-height: 68px;
}

.widget-bg-color-icon .bg-icon-info {
  background-color: rgba(61, 220, 247, 0.75);
  border: 6px solid rgba(61, 220, 247, 0.3);
}

.widget-bg-color-icon .bg-icon-primary {
  background-color: rgba(249, 205, 72, 0.75);
  border: 6px solid rgba(249, 205, 72, 0.3);
}

.widget-bg-color-icon .bg-icon-pink {
  background-color: rgba(247, 99, 151, 0.75);
  border: 6px solid rgba(247, 99, 151, 0.3);
}

.widget-bg-color-icon .bg-icon-purple {
  background-color: rgba(114, 102, 186, 0.75);
  border: 6px solid rgba(114, 102, 186, 0.3);
}

.widget-bg-color-icon .bg-icon-success {
  background-color: rgba(0, 177, 157, 0.75);
  border: 6px solid rgba(0, 177, 157, 0.3);
}

.widget-bg-color-icon .bg-icon-warning {
  background-color: rgba(59, 175, 218, 0.75);
  border: 6px solid rgba(59, 175, 218, 0.3);
}

.widget-bg-color-icon .bg-icon-danger {
  background-color: rgba(239, 83, 80, 0.75);
  border: 6px solid rgba(239, 83, 80, 0.3);
}

.widget-bg-color-icon .bg-icon-inverse {
  background-color: rgba(76, 86, 103, 0.75);
  border: 6px solid rgba(76, 86, 103, 0.3);
}

/* ===========
   Form Elements
 =============*/
label {
  font-weight: 500;
}

.form-control {
  border: 1px solid #dedede;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 1px;
  height: auto;
  padding: 10px 15px;
}

.form-control:focus {
  background: #ffffff;
  border-color: #a2a2a2;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.input-sm {
  height: 30px;
}

.input-group-btn .btn {
  padding: 7px 14px;
}

.has-success .form-control {
  border-color: #39BF6C;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.has-warning .form-control {
  border-color: #DEDA6A;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.has-error .form-control {
  border-color: #ED6163;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.input-group-addon {
  border-radius: 2px;
}

/* =============
   Form Advanced
============= */
/* bootstrap tag input */
.bootstrap-tagsinput {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 3px 7px;
  border: 1px solid #f3f3f3;
}

.bootstrap-tagsinput .label-info {
  background-color: $primary !important;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 3px;
  font-size: 14px;
  margin: 2px;
}

/* Multiple select*/
.ms-container {
  background: transparent url("../images/multiple-arrow.png") no-repeat 50% 50%;
}

.ms-container .ms-list {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #f3f3f3;
}

.ms-container .ms-list.ms-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #aaaaaa;
}

.ms-container .ms-selectable li.ms-elem-selectable {
  border: none;
  padding: 5px 10px;
}

.ms-container .ms-selection li.ms-elem-selection {
  border: none;
  padding: 5px 10px;
}

.search-input {
  margin-bottom: 10px;
}

.ms-selectable {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none !important;
}

.ms-container .ms-list.ms-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.ms-container .ms-selectable li.ms-hover {
  background-color: $primary;
}

.ms-container .ms-selection li.ms-hover {
  background-color: $primary;
}

/* Select 2 */
.select2-container .HIGH_SCHOOL .select2-selection__arrow {
    width: 20px !important;
    // right: 45px !important; this moved the arrow in the middle. ¯\_(ツ)_/¯
}
.select2-container {
	margin-top: 2.5px;
	margin-top: 2.5px;
  width: 100% !important;
}

.select2-container .select2-selection--single {
  border: 1px solid #dedede;
  height: 46px;
  border-radius: 1px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 44px;
  padding-left: 12px;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  height: 44px;
  width: 34px;
  right: 3px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #c8c8c8 transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #c8c8c8 transparent !important;
  border-width: 0 6px 6px 6px !important;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: 1px solid #e1e1e1;
}

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: #fbfbfb;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e1e1e1;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $primary;
  color: #000;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #eeeeee;
  color: #000;
}

.select2-container--default .select2-results__option[aria-selected=true]:hover {
  background-color: $primary;
  color: #000;
}

.select2-container .select2-selection--multiple {
  border: 1px solid #e1e1e1 !important;
  max-height: 46px !important;
  min-height: 44px !important;
  border-radius: 0px !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 2px 10px;
  max-height: 44px !important;
  overflow-y: auto !important;
  min-height: 44px !important;
}

.select2-container .select2-selection--multiple .select2-search__field {
  margin-top: 7px;
  border: 0;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: $primary;
  border: 1px solid transparent;
  color: #000;
  border-radius: 3px;
  padding: 0 7px;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #000;
  margin-right: 5px;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #000;
}

/* Datepicker */
.datepicker {
  padding: 8px;
}

.datepicker th {
  font-size: 14px !important;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover,
.datepicker table tr td.selected, .datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover,
.datepicker table tr td span.active, .datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
  background-image: none;
}

.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled, .datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled], .datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: $primary;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: $primary !important;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-shadow: none;
}

.datepicker thead tr:first-child th:hover, .datepicker tfoot tr th:hover {
  background-color: #fafafa;
}

.datepicker-inline {
  border: 2px solid #eeeeee;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  background-color: $primary !important;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* Date Range Picker */
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: $primary;
  border-color: $primary;
}

.daterangepicker .input-mini.active {
  border: 1px solid #AAAAAA;
}

.daterangepicker .ranges li {
  border-radius: 2px;
  color: #797979;
  font-weight: 600;
  font-size: 12px;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect,
.daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  border: 1px solid #e3e3e3;
  padding: 2px;
  width: 60px;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background-color: $primary;
  border: 1px solid $primary;
}

/* Bootstrap Touchspin */
.bootstrap-touchspin .input-group-addon {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #000;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid rgba(42, 49, 66, 0.15);
}

.bootstrap-touchspin .input-group-btn:not(:first-child) > .btn, .bootstrap-touchspin .input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.bootstrap-touchspin .input-group .form-control:not(:first-child), .bootstrap-touchspin .input-group-addon:not(:first-child), .bootstrap-touchspin .input-group-btn:not(:first-child) > .btn, .bootstrap-touchspin .input-group-btn:not(:first-child) > .btn-group > .btn, .bootstrap-touchspin .input-group-btn:not(:first-child) > .dropdown-toggle, .bootstrap-touchspin .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn, .bootstrap-touchspin .input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-touchspin .input-group .form-control:not(:last-child), .bootstrap-touchspin .input-group-addon:not(:last-child), .bootstrap-touchspin .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn, .bootstrap-touchspin .input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle), .bootstrap-touchspin .input-group-btn:not(:last-child) > .btn, .bootstrap-touchspin .input-group-btn:not(:last-child) > .btn-group > .btn, .bootstrap-touchspin .input-group-btn:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* ===========
   Wizard
 =============*/
.wizard > .content {
  background: #ffffff;
  min-height: 240px;
  padding: 20px;
}

.wizard > .content > .body {
  padding: 0;
  position: relative;
}

.wizard > .content > .body input {
  border: 1px solid #f3f3f3;
}

.wizard > .content > .body ul > li {
  display: block;
  line-height: 30px;
}

.wizard > .content > .body label.error {
  color: #ED6163;
  margin-left: 0;
}

.wizard > .content > .body label {
  display: inline-block;
  margin-top: 10px;
}

.wizard > .steps .number {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  line-height: 30px;
  margin-right: 10px;
  width: 30px;
  text-align: center;
}

.wizard > .steps .disabled a {
  background: #ffffff;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .steps .disabled a:hover {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .steps .disabled a:active {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .steps .current a {
  background: $primary;
}

.wizard > .steps .current a:hover {
  background: $primary;
}

.wizard > .steps .current a:hover .number {
  color: #ffffff;
}

.wizard > .steps .current a:active {
  background: $primary;
}

.wizard > .steps .current a:active .number {
  color: #ffffff;
}

.wizard > .steps .current a .number {
  color: #ffffff;
}

.wizard > .steps .done a {
  background: #dddddd;
}

.wizard > .steps .done a:hover {
  background: #dddddd;
}

.wizard > .steps .done a:active {
  background: #dddddd;
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active, .wizard > .content {
  border-radius: 2px;
}

.wizard > .actions a {
  background: $primary;
  border-radius: 2px;
  color: #ffffff;
}

.wizard > .actions a:hover {
  background: $primary;
  border-radius: 2px;
  color: #ffffff;
}

.wizard > .actions a:active {
  background: $primary;
  border-radius: 2px;
  color: #ffffff;
}

.wizard > .actions .disabled a {
  background: #ffffff;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:hover {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:active {
  background: #f9f9f9;
  color: #333333;
  cursor: default;
  border: 1px solid #eaeaea;
}

@media (max-width: 767px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .wizard > .content {
    padding: 0 !important;
  }
  .wizard > .content > .body {
    float: none;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .wizard.vertical > .steps {
    display: inline;
    float: none;
    width: 100%;
  }
  .wizard.vertical > .content {
    display: inline;
    float: none;
    margin: 0;
    width: 100%;
  }
}

/* ===========
   Tables
 =============*/
.error {
  color: #ED6163;
  font-size: 12px;
  font-weight: 500;
}

.parsley-error {
  border-color: #ED6163 !important;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #ED6163;
}

/* ===========
   Form Uploads
 =============*/
.dropzone {
  min-height: 230px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 6px;
}

.dropzone .dz-message {
  font-size: 30px;
}

/* ===========
   Wysiwig
 =============*/
.mce-content-body p {
  color: #9398a0;
  font-size: 14px;
  font-weight: 300;
}

.mce-popover .mce-arrow:after {
  border-bottom-color: red;
}

.mce-popover .mce-colorbutton-grid {
  margin: 0;
  border: 1px solid #d7dce5 !important;
  padding: 4px;
}

.mce-reset .mce-window-head {
  border-bottom: 1px solid #d7dce5;
}

.mce-reset .mce-window-head .mce-title {
  color: #707780;
  font-size: 16px;
  font-weight: 400;
}

.mce-reset .mce-textbox {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
  border-color: #d7dce5;
  height: 30px;
  font-weight: 300;
  line-height: 30px;
  color: #aaaaaa;
  font-size: 14px;
}

.mce-reset .mce-textbox:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: $primary;
}

.mce-reset .mce-checkbox .mce-ico {
  background-image: none;
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #d7dce5;
}

.mce-reset .mce-checkbox .mce-label {
  color: #707780;
  font-size: 12px;
  font-weight: 400;
}

.mce-container {
  border-radius: 0 !important;
  border-width: 0 !important;
}

.mce-container .mce-menubar {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
  padding: 2px;
}

.mce-container .mce-menubar .mce-btn button span {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.mce-container .mce-menubar .mce-btn button .mce-caret {
  border-top-color: #707780;
}

.mce-container .mce-menubar .mce-btn button:hover {
  background-color: #e8ebf1;
}

.mce-container .mce-menubar .mce-btn.mce-active button {
  background-color: #e8ebf1;
}

.mce-container .mce-btn {
  background-color: #d7dce5;
  background-image: none;
  outline: 0;
  border: 0;
  border-radius: 0;
}

.mce-container .mce-btn button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: none;
}

.mce-container .mce-btn:hover {
  background-color: #b8c1d1;
  background-image: none;
}

.mce-container .mce-primary {
  background-color: $primary;
  background-image: none;
  outline: 0;
  border: 0;
  border-radius: 0;
}

.mce-container .mce-primary button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: none;
}

.mce-container .mce-primary:hover {
  background-color: #0c7cd5;
  background-image: none;
}

.mce-container .mce-toolbar-grp {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
  border-top-width: 0 !important;
  padding: 6px;
}

.mce-container .mce-edit-area {
  border: 1px solid #d7dce5 !important;
  border-width: 0px 1px !important;
}

.mce-container .mce-statusbar {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
}

.mce-container .mce-statusbar .mce-path .mce-path-item {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
}

.mce-container .mce-widget {
  color: #9398a0;
  font-size: 14px;
  font-weight: 400;
  border-left: 1px solid transparent;
}

.mce-container .mce-btn-group {
  border: 1px solid #e9ecf2 !important;
}

.mce-container .mce-btn-group .mce-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-image: none;
  background-color: #ffffff;
  border-width: 0;
  border-radius: 0 !important;
}

.mce-container .mce-btn-group .mce-btn:hover,
.mce-container .mce-btn-group .mce-btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-image: none;
  background-color: #ffffff;
}

.mce-container .mce-btn-group .mce-btn button span {
  color: #707780;
  font-size: 14px;
  font-weight: 300;
}

.mce-container .mce-btn-group .mce-btn button .mce-caret {
  color: #707780;
  font-size: 14px;
}

.mce-container .mce-ico {
  color: #707780;
  font-size: 14px;
}

.mce-container .mce-panel {
  background-image: none;
}

.mce-container.mce-menu {
  border: 1px solid #d7dce5 !important;
}

.mce-container.mce-menu .mce-menu-item {
  background-image: none;
}

.mce-container.mce-menu .mce-menu-item .mce-ico {
  color: $primary;
  font-size: 14px;
}

.mce-container.mce-menu .mce-menu-item .mce-text {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.mce-container.mce-menu .mce-menu-item .mce-menu-shortcut {
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
}

.mce-container.mce-menu .mce-menu-item:hover,
.mce-container.mce-menu .mce-menu-item:focus,
.mce-container.mce-menu .mce-menu-item.mce-selected {
  background-color: $primary;
}

.mce-container.mce-menu .mce-menu-item:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item:hover .mce-text,
.mce-container.mce-menu .mce-menu-item:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-menu-shortcut {
  color: #ffffff;
}

.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-menu-shortcut {
  color: #aaaaaa;
}

.mce-container.mce-menu .mce-menu-item.mce-disabled:hover,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected {
  background-color: #d7dce5;
}

.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-menu-shortcut {
  color: #ffffff;
}

.mce-container.mce-menu .mce-menu-item-sep {
  background-color: #d7dce5;
}

.mce-container.mce-menu .mce-menu-item-sep:hover {
  background-color: #d7dce5;
}

.mce-menubtn button {
  color: #797979 !important;
}

.mce-menu-item-normal.mce-active {
  background-color: $primary !important;
}

.mce-menu-item-normal.mce-active .mce-text {
  color: #ffffff !important;
}

/* ==============
  Summernote
===================*/
.note-btn-group .dropdown-menu > li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.note-btn-group .dropdown-menu > li > a:hover {
  background-color: #f3f3f3;
}

.note-image-popover, .note-air-popover, .note-link-popover {
  display: none;
}

.note-image-popover .dropdown-toggle::after, .note-air-popover .dropdown-toggle::after, .note-link-popover .dropdown-toggle::after {
  margin-left: 0;
}

.note-icon-caret {
  display: none;
}

.note-editor {
  position: relative;
}

.note-editor .btn-default {
  background-color: transparent;
  border-color: transparent;
}

.note-editor .btn-group-sm > .btn, .note-editor .btn-sm {
  padding: 8px 12px;
}

.note-editor .note-toolbar {
  background-color: #f3f3f3;
  border-bottom: 1px solid #eeeeee;
  margin: 0;
}

.note-editor .note-statusbar {
  background-color: #ffffff;
}

.note-editor .note-statusbar .note-resizebar {
  border-top: none;
  height: 15px;
  padding-top: 3px;
}

.note-editor.note-frame {
  border: 1px solid #eeeeee;
}

.note-popover .popover .popover-content {
  padding: 5px 0 10px 5px;
}

.note-popover .btn-default {
  background-color: transparent;
  border-color: transparent;
}

.note-popover .btn-group-sm > .btn, .note-popover .btn-sm {
  padding: 8px 12px;
}

.note-toolbar {
  padding: 5px 0 10px 5px;
}

/* ===========
   Tables
 =============*/
.table {
  margin-bottom: 10px;
}



.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  border-color: #f3f3f3;
}

.table > thead > tr > td.middle-align, .table > tbody > tr > td.middle-align {
  vertical-align: middle;
}

.table > thead > tr > th {
  background-color: #000;
  color: #fff;
  border-top: solid 4px $primary;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 16px;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.table > tbody > tr.success > td, .table > tbody > tr.success > th,
.table > tbody > tr > td.success, .table > tbody > tr > th.success,
.table > tfoot > tr.success > td, .table > tfoot > tr.success > th,
.table > tfoot > tr > td.success, .table > tfoot > tr > th.success,
.table > thead > tr.success > td, .table > thead > tr.success > th,
.table > thead > tr > td.success, .table > thead > tr > th.success {
  background-color: fade(#39BF6C, 15%);
}

.table > tbody > tr.info > td, .table > tbody > tr.info > th,
.table > tbody > tr > td.info, .table > tbody > tr > th.info,
.table > tfoot > tr.info > td, .table > tfoot > tr.info > th,
.table > tfoot > tr > td.info, .table > tfoot > tr > th.info,
.table > thead > tr.info > td, .table > thead > tr.info > th,
.table > thead > tr > td.info, .table > thead > tr > th.info {
  background-color: fade(#57BCFA, 15%);
}

.table > tbody > tr.warning > td, .table > tbody > tr.warning > th,
.table > tbody > tr > td.warning, .table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning,
.table > thead > tr.warning > td, .table > thead > tr.warning > th,
.table > thead > tr > td.warning, .table > thead > tr > th.warning {
  background-color: fade(#DEDA6A, 15%);
}

.table > tbody > tr.danger > td, .table > tbody > tr.danger > th,
.table > tbody > tr > td.danger, .table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger,
.table > thead > tr.danger > td, .table > thead > tr.danger > th,
.table > thead > tr > td.danger, .table > thead > tr > th.danger {
  background-color: fade(#ED6163, 15%);
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e !important;
}

.table-centered td, .table-centered th {
  vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F8F8F8;
}

/* Datatable */
div.dataTables_paginate ul.pagination {
  margin-top: 30px;
}

div.dataTables_info {
  padding-top: 38px;
}

.dt-buttons {
  float: left;
}

div#datatable-buttons_info {
  float: left;
}

table.datatable {
  thead {
    .sorting::before {
      font-size: 14px;
    }
    .sorting::after {
      font-size: 14px;
      margin-left: 1px !important;
    }
  }
}

.table-wrapper .btn-toolbar {
  display: block;
}

.table-wrapper .dropdown-menu {
  left: auto;
  right: 0;
}

.tablesaw-columntoggle-btnwrap .btn-group {
  display: block;
}

table.dataTable th.focus, table.dataTable td.focus {
  outline: 2px solid $primary !important;
  outline-offset: -1px;
  background-color: rgba(249, 205, 72, 0.15);
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: $primary;
  color: #000;
}

/* Responsive data table */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  -webkit-box-shadow: 0 0 3px rgba(67, 89, 102, 0.2);
          box-shadow: 0 0 3px rgba(67, 89, 102, 0.2);
  background-color: #39BF6C;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #ED6163;
}

.page-item.active .page-link {
  color: #000;
}

/* Responsive Table (Plugin) */
.table-rep-plugin .dropdown-menu li.checkbox-row {
  padding: 2px 15px !important;
}

.table-rep-plugin .table-responsive {
  border: none !important;
}

.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}

.table-rep-plugin .checkbox-row {
  padding-left: 40px;
}

.table-rep-plugin .checkbox-row label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}

.table-rep-plugin .checkbox-row label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}

.table-rep-plugin .checkbox-row label::after {
  color: #f3f3f3;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: -1px;
  width: 16px;
}

.table-rep-plugin .checkbox-row input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::before {
  background-color: #ffffff;
  border-color: $primary;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  color: $primary;
}

.table-rep-plugin table.focus-on tbody tr.focused th, .table-rep-plugin table.focus-on tbody tr.focused td,
.table-rep-plugin .sticky-table-header {
  background-color: $primary;
  color: #ffffff;
  border-color: $primary;
}

.table-rep-plugin .sticky-table-header.fixed-solution {
  top: 70px !important;
}

.table-rep-plugin .btn-default {
  background-color: #ffffff;
  border: 1px solid rgba(42, 49, 66, 0.3);
}

.table-rep-plugin .btn-default.btn-primary {
  background-color: $primary;
}

.table-rep-plugin .btn-toolbar {
  display: block;
}

.table-rep-plugin .btn-group.pull-right {
  float: right;
}

.table-rep-plugin .btn-group.pull-right .dropdown-menu {
  left: auto;
  right: 0;
}

.modal-block {
  background: transparent;
  margin: 40px auto;
  max-width: 600px;
  padding: 0;
  position: relative;
  text-align: left;
}

/* Data table editable */
.add-edit-table td, .add-edit-table th {
  vertical-align: middle !important;
}

.add-edit-table td {
  border: 0 !important;
}

#datatable-editable .actions a {
  padding: 5px;
}

#datatable-editable .form-control {
  background-color: #ffffff;
  width: auto;
  height: 20px;
}

#datatable-editable .fa-trash-o {
  color: #ED6163;
}

#datatable-editable .fa-times {
  color: #ED6163;
}

#datatable-editable .fa-pencil {
  color: $primary;
}

#datatable-editable .fa-save {
  color: #39BF6C;
}

#datatable td {
  font-weight: normal;
}

.modal-block {
  background: transparent;
  margin: 40px auto;
  max-width: 600px;
  padding: 0;
  position: relative;
  text-align: left;
}

/* Tablesaw */
.tablesaw thead {
  background: #f5f5f5;
  background-image: none;
  border: none;
}

.tablesaw thead th {
  text-shadow: none;
  letter-spacing: 0.06em;
}

.tablesaw thead tr:first-child th {
  padding-top: 1.1em;
  padding-bottom: 0.9em;
}

.tablesaw thead tr:first-child th {
  font-weight: 600;
  font-family: inherit;
  border: none;
}

.tablesaw td, .tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}

.tablesaw-stack tbody tr, .tablesaw tbody tr {
  border-bottom: none;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after, .tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  font-family: FontAwesome;
  font-size: 10px;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
  content: "\f176";
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  content: "\f175";
}

.tablesaw-bar .btn-select.btn-small:after, .tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
}

.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
  background: #ffffff;
}

.tablesaw-enhanced .tablesaw-bar .btn:hover, .tablesaw-enhanced .tablesaw-bar .btn:focus, .tablesaw-enhanced .tablesaw-bar .btn:active {
  color: $primary !important;
  background-color: #f5f5f5;
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-image: none;
}

/* Footable */
.footable-odd {
  background-color: #ffffff;
}

.footable-detail-show {
  background-color: #e6e6e6;
}

.footable > thead > tr > th > span.footable-sort-indicator {
  float: right;
}

.footable-pagination li {
  margin-left: 5px;
  display: inline-block;
  float: left;
}

.footable-pagination li a {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.footable-pagination li.active a {
  color: #ffffff;
}

/* ===========
  Maps
 =============*/
.gmaps, .gmaps-panaroma {
  height: 300px;
  background: #eeeeee;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: $primary;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid $primary;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid $primary;
}

/* Mapael Map */
.mapael .map {
  position: relative;
}

.mapael .mapTooltip {
  position: absolute;
  background-color: #000;
  opacity: 0.95;
  border-radius: 3px;
  padding: 2px 10px;
  z-index: 1000;
  max-width: 200px;
  display: none;
  color: #ffffff;
}

.mapael .zoomIn, .mapael .zoomOut, .mapael .zoomReset {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
  background-color: #000;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 10px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.map .zoomIn {
  top: 25px;
}

.map .zoomOut {
  top: 50px;
}

/* =============
   Email
============= */
.mails a {
  color: #797979;
}

.mails td {
  vertical-align: middle !important;
  position: relative;
}

.mails td:last-of-type {
  width: 100px;
  padding-right: 20px;
}

.mails tr:hover .text-white {
  display: none;
}

.mails .mail-select {
  padding: 12px 20px;
  min-width: 134px;
}

.mails .checkbox {
  margin-bottom: 0;
  margin-top: 0;
  vertical-align: middle;
  display: inline-block;
  height: 17px;
}

.mails .checkbox label {
  min-height: 16px;
}

.mail-list .list-group-item {
  background-color: transparent;
  color: #000;
  font-size: 0.95rem;
}

.mail-list .list-group-item:hover {
  background-color: #eeeeee;
}

.mail-list .list-group-item:focus {
  background-color: #eeeeee;
}

.mail-list .list-group-item.active {
  color: #ED6163;
}

.unread a {
  font-weight: 600;
  color: #444444;
}

/* ===========
   Calendar
 =============*/
.calendar {
  float: left;
  margin-bottom: 0;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin-bottom: 5px;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day {
  background: #ffffff;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active,
.fc-toolbar button:focus, .fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc-widget-header {
  border: 1px solid #d5d5d5;
}

.fc-widget-content {
  border: 1px solid #d5d5d5;
}

.fc th.fc-widget-header {
  background: #dddddd;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  text-transform: uppercase;
}

.fc-button {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  color: #555555;
  text-transform: capitalize;
}

.fc-text-arrow {
  font-family: arial;
  font-size: 16px;
}

.fc-state-hover {
  background: #F5F5F5;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-event .fc-content {
  color: #ffffff;
}

.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}

.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}

/* ===========
  Timeline
 =============*/
.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;
}

.timeline .time-show {
  margin-bottom: 30px;
  margin-right: -75px;
  margin-top: 30px;
  position: relative;
}

.timeline .time-show a {
  color: #ffffff;
}

.timeline:before {
  background-color: #d8d9df;
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 1px;
  z-index: 0;
}

.timeline .timeline-icon {
  -webkit-border-radius: 50%;
  background: #d8d9df;
  border-radius: 50%;
  border: 1px solid #d8d9df;
  color: #ffffff;
  display: block;
  height: 20px;
  left: -54px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px;
}

.timeline .timeline-icon i {
  margin-top: 9px;
}

.timeline .time-icon:before {
  font-size: 16px;
  margin-top: 5px;
}

h3.timeline-title {
  color: #c8ccd7;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.timeline-item {
  display: table-row;
}

.timeline-item:before {
  content: "";
  display: block;
  width: 50%;
}

.timeline-item .timeline-desk .arrow {
  border-bottom: 8px solid transparent;
  border-right: 8px solid #ffffff !important;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: -7px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 0;
}

.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%;
}

.timeline-item.alt .timeline-desk .arrow-alt {
  border-bottom: 8px solid transparent;
  border-left: 8px solid #ffffff !important;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: auto;
  margin-top: -10px;
  position: absolute;
  right: -7px;
  top: 50%;
  width: 0;
}

.timeline-item.alt .timeline-desk .album {
  float: right;
  margin-top: 20px;
}

.timeline-item.alt .timeline-desk .album a {
  float: right;
  margin-left: 5px;
}

.timeline-item.alt .timeline-icon {
  left: auto;
  right: -56px;
}

.timeline-item.alt:before {
  display: none;
}

.timeline-item.alt .panel {
  margin-left: 0;
  margin-right: 45px;
}

.timeline-item.alt .panel .panel-body p + p {
  margin-top: 10px !important;
}

.timeline-item.alt h4 {
  text-align: right;
}

.timeline-item.alt p {
  text-align: right;
}

.timeline-item.alt .timeline-date {
  text-align: right;
}

.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}

.timeline-desk h4 {
  font-size: 16px;
  margin: 0;
}

.timeline-desk .panel {
  background: #ffffff;
  display: block;
  margin-bottom: 5px;
  margin-left: 45px;
  position: relative;
  text-align: left;
  padding: 15px;
  border-radius: 5px;
}

.timeline-desk h5 span {
  color: #797979;
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
}

.timeline-desk p {
  color: #999999;
  font-size: 14px;
  margin-bottom: 0;
}

.timeline-desk .album {
  margin-top: 12px;
}

.timeline-desk .album a {
  float: left;
  margin-right: 5px;
}

.timeline-desk .album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}

.timeline-desk .notification {
  background: none repeat scroll 0 0 #ffffff;
  margin-top: 20px;
  padding: 8px;
}

/* ===========
   Timeline-2
 =============*/
.timeline-2 {
  border-left: 2px solid rgba(255,255,255,.8);
  position: relative;
}

.timeline-2 .time-item:after {
  background-color: #ffffff;
  border-color: rgb(255,255,255);
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 10px;
  left: 0;
  margin-left: -6px;
  position: absolute;
  top: 5px;
  width: 10px;
}

.time-item {
  border-color: #dee5e7;
  padding-bottom: 10px;
  position: relative;
}

.time-item:before {
  content: " ";
  display: table;
}

.time-item:after {
  background-color: #ffffff;
  border-color: $primary;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.time-item-item:after {
  content: " ";
  display: table;
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}

.item-info p {
  font-size: 13px;
  color: rgba(255,255,255,.6);
}

/* =========== */
/* Sweet Alert */
/* =========== */
.swal2-modal {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.swal2-modal .swal2-title {
  font-size: 28px;
}

.swal2-modal .swal2-content {
  font-size: 16px;
}

.swal2-modal .swal2-spacer {
  margin: 10px 0;
}

.swal2-modal .swal2-file, .swal2-modal .swal2-input, .swal2-modal .swal2-textarea {
  border: 2px solid #98a6ad;
  font-size: 16px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.swal2-modal .swal2-confirm.btn-confirm {
  background-color: $primary !important;
  color: #000 !important;
}

.swal2-modal .swal2-cancel.btn-cancel {
  background-color: #ED6163 !important;
}

.swal2-modal .swal2-styled:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.swal2-icon.swal2-question {
  color: $primary;
  border-color: $primary;
}

.swal2-icon.swal2-success {
  border-color: #39BF6C;
}

.swal2-icon.swal2-success .line, .swal2-icon.swal2-success [class^=swal2-success-line][class$=long],
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #39BF6C;
}

.swal2-icon.swal2-success .placeholder, .swal2-icon.swal2-success .swal2-success-ring {
  border-color: #39BF6C;
}

.swal2-icon.swal2-warning {
  color: #DEDA6A;
  border-color: #DEDA6A;
}

.swal2-icon.swal2-error {
  border-color: #ED6163;
}

.swal2-icon.swal2-error .line {
  background-color: #ED6163;
}

.swal2-modal .swal2-file:focus, .swal2-modal .swal2-input:focus, .swal2-modal .swal2-textarea:focus {
  outline: 0;
  border: 2px solid $primary;
}

.swal2-container.swal2-shown {
  background-color: rgba(42, 49, 66, 0.9);
}

/* =============
   Notification
============= */
.notifyjs-metro-base {
  position: relative;
  min-height: 52px;
  min-width: 250px;
  color: #444;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation: dropdownOpen 0.3s ease-out;
  animation: dropdownOpen 0.3s ease-out;
}

.notifyjs-metro-base .image {
  display: table;
  position: absolute;
  height: auto;
  width: auto;
  left: 25px;
  top: 50%;
  font-size: 24px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notifyjs-metro-base .text-wrapper {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 10px 10px 10px 52px;
  clear: both;
}

.notifyjs-metro-base .title {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.notifyjs-metro-base .text {
  font-size: 12px;
  font-weight: normal;
  max-width: 360px;
  vertical-align: middle;
}

.notifyjs-metro-cool {
  color: #fafafa !important;
  background-color: #4A525F;
  border: 1px solid #4A525F;
}

.notifyjs-metro-custom {
  color: #fff !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
}
.notifyjs-metro-primary {
  color: #000 !important;
  background-color: $primary !important;
  border: 1px solid $primary !important;
}
.notifyjs-metro-info {
  color: #fff !important;
  background-color: #57BCFA !important;
  border: 1px solid #57BCFA !important;
}
.notifyjs-metro-warning {
  color: #fff !important;
  background-color: #DEDA6A !important;
  border: 1px solid #DEDA6A !important;
}
.notifyjs-metro-success {
  color: #fff !important;
  background-color: #39BF6C !important;
  border: 1px solid #39BF6C !important;
}
.notifyjs-metro-danger, .notifyjs-metro-error {
  color: #fff !important;
  background-color: #ED6163 !important;
  border: 1px solid #ED6163 !important;
}

/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
  background: rgba(152, 166, 173, 0.25) !important;
  border: none;
  padding: 8px 16px;
  height: auto;
  font-weight: 600;
  border-radius: 3px;
}

.custom-dd .dd-list .dd-item .dd-handle:hover {
  color: $primary;
}

.custom-dd .dd-list .dd-item button {
  height: auto;
  font-size: 17px;
  margin: 8px auto;
  color: #555555;
  width: 30px;
}

.custom-dd-empty .dd-list .dd3-handle {
  border: none;
  background: rgba(152, 166, 173, 0.25) !important;
  height: 36px;
  width: 36px;
}

.custom-dd-empty .dd-list .dd3-handle:before {
  color: inherit;
  top: 7px;
}

.custom-dd-empty .dd-list .dd3-handle:hover {
  color: $primary;
}

.custom-dd-empty .dd-list .dd3-content {
  height: auto;
  border: none;
  padding: 8px 16px 8px 46px;
  background: rgba(152, 166, 173, 0.25) !important;
  font-weight: 600;
}

.custom-dd-empty .dd-list .dd3-content:hover {
  color: $primary;
}

.custom-dd-empty .dd-list button {
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: 600;
}

/* =============
   Charts
============= */
.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  background: #36404a;
  border: none;
  color: #ffffff !important;
}

.chart-detail-list li {
  margin: 0 10px;
}

.chart-detail-list li h5 {
  font-size: 15px;
}

.pieLabel div {
  font-size: 14px !important;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  color: #797979;
}

.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #36404a;
  z-index: 100;
  color: #ffffff;
  opacity: 0.9;
  font-size: 13px;
  border-radius: 3px;
}

.legend tr {
  height: 20px;
}

.legendLabel {
  padding-left: 10px !important;
  line-height: 10px;
  padding-right: 10px;
}

/* Chartist chart */
.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 300px;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 12px;
  line-height: 1;
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-bar, .ct-chart .ct-series.ct-series-a .ct-line, .ct-chart .ct-series.ct-series-a .ct-point, .ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: $primary;
}

.ct-chart .ct-series.ct-series-b .ct-bar, .ct-chart .ct-series.ct-series-b .ct-line, .ct-chart .ct-series.ct-series-b .ct-point, .ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #f76397;
}

.ct-chart .ct-series.ct-series-c .ct-bar, .ct-chart .ct-series.ct-series-c .ct-line, .ct-chart .ct-series.ct-series-c .ct-point, .ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #39BF6C;
}

.ct-chart .ct-series.ct-series-d .ct-bar, .ct-chart .ct-series.ct-series-d .ct-line, .ct-chart .ct-series.ct-series-d .ct-point, .ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #57BCFA;
}

.ct-chart .ct-series.ct-series-e .ct-bar, .ct-chart .ct-series.ct-series-e .ct-line, .ct-chart .ct-series.ct-series-e .ct-point, .ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #797979;
}

.ct-chart .ct-series.ct-series-f .ct-bar, .ct-chart .ct-series.ct-series-f .ct-line, .ct-chart .ct-series.ct-series-f .ct-point, .ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #7266ba;
}

.ct-chart .ct-series.ct-series-g .ct-bar, .ct-chart .ct-series.ct-series-g .ct-line, .ct-chart .ct-series.ct-series-g .ct-point, .ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #DEDA6A;
}

.ct-series-a .ct-area, .ct-series-a .ct-slice-pie {
  fill: $primary;
}

.ct-series-b .ct-area, .ct-series-b .ct-slice-pie {
  fill: #f76397;
}

.ct-series-c .ct-area, .ct-series-c .ct-slice-pie {
  fill: #39BF6C;
}

.ct-series-d .ct-area, .ct-series-d .ct-slice-pie {
  fill: #57BCFA;
}

/* Sparkline chart */
.jqstooltip {
  background-color: #000 !important;
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #000 !important;
}

.jqsfield {
  font-size: 12px !important;
  line-height: 18px !important;
}

/* Circliful charts */
.circliful-chart {
  margin: 0 auto;
}

.circle-text,
.circle-info,
.circle-text-half,
.circle-info-half {
  font-size: 12px;
  font-weight: 600;
}

/* ===========
   Count Down
 =============*/
.home-wrapper {
  margin: 10% 0;
}

.app-countdown {
  margin-top: 40px;
  text-align: center;
}

.app-countdown div {
  display: inline-block;
}

.app-countdown div span {
  display: block;
  width: 150px;
}

.app-countdown div span:first-child {
  font-size: 3em;
  font-weight: 700;
  height: 48px;
  line-height: 48px;
}

.app-countdown div span:last-child {
  color: #333333;
  font-size: 0.9em;
  height: 25px;
  line-height: 25px;
}

.app-countdown > * {
  text-align: center;
}

/* ===========
   Gallery
 =============*/
.portfolioFilter a {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #000;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}

.portfolioFilter a:hover {
  background-color: $primary;
  color: #ffffff;
}

.portfolioFilter a.current {
  background-color: $primary;
  color: #ffffff;
}

.thumb {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  padding: 10px;
  width: 100%;
}

.thumb-img {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
}

.gal-detail h4 {
  margin: 16px auto 10px auto;
  width: 80%;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  font-size: 18px;
  text-overflow: ellipsis;
}

.gal-detail p {
  margin-bottom: 10px;
}

.gal-detail .ga-border {
  height: 3px;
  width: 40px;
  background-color: $primary;
  margin: 10px auto;
}

/* ===========
   Maintenance
 =============*/
.icon-main {
  font-size: 60px;
}

.maintenance-page {
  margin: 10% 0;
}

.home-text {
  letter-spacing: 1px;
}

.maintenance-container {
  background: #000;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.maintenance-image {
  max-width: 1500px;
  padding: 3rem;
  position: absolute;
}

.maintenance-text {
  max-width: 600px;
  padding: 3rem;
  position: absolute;
  color: #fff;
  margin-top: 28rem;
  text-align: center;
}

/* ===========
   Accounts
 =============*/
.wrapper-page {
  margin: 7.5% auto;
  max-width: 360px;
}

.wrapper-page .form-control {
  height: 40px;
}

.logo-lg {
  font-size: 30px !important;
  font-weight: 700;
  line-height: 70px;
  color: $primary !important;
}

.user-thumb img {
  height: 88px;
  margin: 0 auto;
  width: 88px;
}

.ex-page-content .svg-box {
  float: right;
}

.message-box {
  margin: 120px 50px;
}

.message-box h1 {
  color: #252932;
  font-size: 98px;
  font-weight: 700;
  line-height: 98px;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  -webkit-animation: float 1s infinite ease-in-out alternate;
          animation: float 1s infinite ease-in-out alternate;
}

#Polygon-2 {
  -webkit-animation-delay: .2s;
          animation-delay: .2s;
}

#Polygon-3 {
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}

#Polygon-4 {
  -webkit-animation-delay: .6s;
          animation-delay: .6s;
}

#Polygon-5 {
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

@-webkit-keyframes float {
  100% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}

@keyframes float {
  100% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}

/* =============
   Tree view page
============= */
.jstree-default .jstree-clicked, .jstree-default .jstree-wholerow-clicked {
  background: rgba(249, 205, 72, 0.4);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.jstree-default .jstree-hovered, .jstree-default .jstree-wholerow-hovered {
  background: rgba(249, 205, 72, 0.2);
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* =============
   Pricing
============= */
.pricing-column {
  position: relative;
  margin-bottom: 40px;
}

.pricing-column .inner-box {
  position: relative;
  padding: 0 0 50px;
}

.pricing-column .plan-header {
  position: relative;
  padding: 30px 20px 25px;
}

.pricing-column .plan-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #39BF6C;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.pricing-column .plan-price {
  font-size: 48px;
  margin-bottom: 10px;
  color: #000;
}

.pricing-column .plan-duration {
  font-size: 13px;
  color: #98a6ad;
}

.pricing-column .plan-stats {
  position: relative;
  padding: 30px 20px 15px;
}

.pricing-column .plan-stats li {
  margin-bottom: 15px;
  line-height: 24px;
}

.pricing-column .plan-stats li i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.ribbon {
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  background: #39BF6C;
  background: -webkit-gradient(linear, left top, left bottom, from(#39BF6C), to(#39BF6C));
  background: linear-gradient(#39BF6C 0%, #39BF6C 100%);
  position: absolute;
  top: 19px;
  letter-spacing: 1px;
  left: -21px;
}

.ribbon span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #007e70;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #007e70;
}

.ribbon span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #007e70;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #007e70;
}

/* =============
   FAQ
============= */
.question-q-box {
  height: 30px;
  width: 30px;
  color: #ffffff;
  background-color: #ED6163;
  text-align: center;
  border-radius: 50%;
  float: left;
  line-height: 26px;
  font-weight: 700;
}

.question {
  margin-top: 0;
  margin-left: 50px;
  font-size: 16px;
}

.answer {
  margin-left: 50px;
  color: #98a6ad;
  margin-bottom: 40px;
  line-height: 26px;
}

/* =============
   Task board
============= */
.taskList {
  min-height: 40px;
  margin-bottom: 0;
}

.taskList li {
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  font-size: 14px;
}

.taskList li:last-of-type {
  margin-bottom: 0;
}

.taskList li .btn-sm {
  padding: 2px 8px;
  font-size: 12px;
}

.taskList li.task-success {
  border-left: 3px solid rgba(0, 177, 157, 0.5);
}

.taskList li.task-info {
  border-left: 3px solid rgba(61, 220, 247, 0.5);
}

.taskList li.task-danger {
  border-left: 3px solid rgba(239, 83, 80, 0.5);
}

.taskList li.task-warning {
  border-left: 3px solid rgba(59, 175, 218, 0.5);
}

.taskList a {
  font-size: 13px;
  font-weight: 500;
}

.taskList .checkbox {
  margin-left: 20px;
  margin-top: 5px;
}

.task-placeholder {
  border: 1px dashed #eeeeee !important;
  background-color: rgba(238, 238, 238, 0.2) !important;
  padding: 20px;
}

/* =============
   Coach Profile
============= */
@media (max-width: 384px) {
    .profile-pic-lg {
        height: 125px !important;
        width: 125px !important
    }
}

/* =============
   Player Profile
============= */
// #account .select2-container {
//     height: auto !important;
// }

/* =============
  All Datatables
   - Adjust Up/Down arrows
============= */
table th:before {
  margin-left: -11px !important;
}

table th:after {
    margin-left: 0 !important;
}

/* =============
  Super Admin Datatables
============= */
.big-board-modal .close {
    background-color: #000;
    border-radius: 50%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    height: 36px;
    margin: -1rem -1rem -1rem auto;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 2.5%;
    text-shadow: none;
    top: 27.5%;
    width: 36px;
    z-index: 9999;
}
.big-board-modal .dropdown-menu {
    max-height: 200px;
    overflow: scroll;
}
.dropdown-menu .dropdown-item {
    font-family: 'KairosSans';
}

@media (max-width: 1000px) {
    .big-board-modal .close {
        right: 3%;
        top: 3.5%;
    }
}
@media (max-width: 575px) {
    .big-board-modal .close {
        right: 4%;
    }
}
#organizations-table_filter .form-control {
    font-family: 'KairosSans';
    text-transform: uppercase;
    width: 152px;
}
#users-table_filter .form-control {
    font-family: 'KairosSans';
    text-transform: uppercase;
    width: 152px;
}
#users-table_wrapper {
    .account-info {
        display: flex;
        justify-content: center;
        .inner-info {
            display: flex;
            justify-content: center;
        }
        .full-info {

            border-radius: 60px;
            margin: 0px 36px;
            .round {
                border: none !important;
            }
        }
        .round {
            border-radius: 50%;
            border: 1px solid #666;
            background: #E7E7E7;
            height: 28px;
            width: 28px;
            position: relative;
            i {
                left: 50% !important;
                top: 50%;
                font-size: 14px;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
        .org-bg {
          background: #E7F816;
        }
        .org-text {
          color: #000;
        }
    }
}

/* Custom Filters for Users Table */
#users-filter {
  margin-bottom: 5px;
}
#users-filter #clearFilters {
  float: right;
  height: 44px;
}
#users-filter .select2-container {
  margin-left: 5px !important;
  padding-left: 0;
  width: 152px !important;
}

@media(max-width: 768px) {
    #organizations-table_filter .form-control {
        width: 100%;
    }
    #users-table_filter .form-control {
        width: 100%;
    }
}

@media(max-width: 575px) {
  #users-filter {
    width: 100%;
  }
  #users-filter .clear-filter-wrapper {
    margin-bottom: 10px;
    width: 100%;
  }
  #users-filter .select2-container {
    margin: 0 !important;
    width: 50% !important;
  }
}

/* =============
   Recruit Profile
=============*/

#new-note .multiselect__placeholder {
    color: black;
}
#new-note .multiselect__tag-icon:hover {
    background: none !important;
}
#new-note .multiselect__tag-icon:hover::after {
    color: red !important;
}

/* ===========
   Page Stylings
 =============*/

/* Search Inputs */
#offers-filter .form-control {
    font-family: 'KairosSans';
    text-transform: uppercase;
}
/* Pagination */
.pagination .page-item .page-link:focus {
    box-shadow: none !important;
}
.page-item.active .page-link:focus {
    box-shadow: none !important;
}
/* Prospect Search */
#roster-filter .form-control {
    height: 46px;
    margin-bottom: 0 !important;
}

#roster-filter .select2-container {
    margin-bottom: 0 !important;
}

/* Data Tables*/
div.dataTables_processing {
    position: fixed !important;
    z-index: 1 !important;
}
.dataTable .vertical-center {
    vertical-align: middle;
}

/* ===========
   Create Organization
 =============*/
@media(min-width: 1200px) and (max-width: 1221px) {
    .fullname-mb {
        margin-bottom: 30px;
    }
}

/*
Template Name: Minton Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Responsive
*/
@media only screen and (max-width: 6000px) and (min-width: 700px) {
  .wrapper.right-bar-enabled .right-bar {
    right: 0;
    z-index: 99;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .email-msg {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
  .enlarged .left.side-menu {
    margin-left: -75px;
  }
  .content-page {
    margin-left: 0px !important;
  }
  .footer {
    left: 0px !important;
  }
  .mobile-sidebar {
    left: 0px;
  }
  .mobile-content {
    left: 250px;
    right: -250px;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    left: auto;
    position: absolute;
    right: 0;
  }
  .todo-send {
    margin-top: 10px;
    padding-left: 15px;
  }
  .chat-inputbar {
    padding-left: 15px;
  }
  .chat-send {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dataTables_wrapper .col-xs-6 {
    width: 100%;
    text-align: left;
  }
  .app-search {
    display: none;
  }
}

@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }
  .button-menu-mobile {
    display: block;
  }
  .search-bar {
    display: none !important;
  }
  .user-detail {
    bottom: 0;
    background-color: #ffffff;
    z-index: 1;
  }
  #sidebar-menu {
    overflow: auto;
    height: 100%;
    padding-top: 0;
    padding-bottom: 75px;
  }
  .navbar-custom {
    margin-left: 70px !important;
  }
  .page-title-box .page-title {
    float: none;
    text-align: center;
    margin-bottom: 20px;
  }
  .page-title-box .breadcrumb {
    float: none !important;
    font-size: 13px;
  }
  .dropdown-menu-lg {
    max-width: 230px;
  }
}

@media (max-width: 991px) {
  .hide-tablet {
    display: none;
  }
}

@media (max-width: 420px) {
  .hide-phone {
    display: none !important;
  }
}

/* Container-alt */
@media (min-width: 768px) {
  .container-alt {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container-alt {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container-alt {
    width: 1170px;
  }
}

@media (max-width: 419px) {
  .topbar-left {
    width: 70px !important;
  }
  .logo .icon-c-logo {
    display: inline-block !important;
    line-height: 58px !important;
  }
  .logo span {
    display: none !important;
  }
  .content-page {
    margin-left: 70px;
  }
  .forced .side-menu.left {
    -webkit-box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
            box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
  }
  .enlarged .side-menu.left {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
  }
}

@media (max-width: 389px) {
    .tabs-bordered .nav-item {
        width: 50%;
    }
}

@media (max-width: 1195px) {
    .mentor-mb label {
        margin-bottom: 30px;
    }
    .hobby-mb label {
        margin-bottom: 30px;
    }
}
@media (max-width: 858px) {
    .mentor-mb label {
        margin-bottom: 0.5rem;
    }
}
@media (max-width: 767px) {
    .hobby-mb label {
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 800px) and (min-width: 576px) {
    #rosterSearchInfo #roster-search {
        width: 33% !important;
    }
    #rosterSearchInfo #roster-filter .select2 {
        width: calc(33% - 2px) !important;
    }
}

@media (max-width: 575px) {
    #offers-filter #offers-search {
        width: 60% !important;
    }
    #offers-filter .offer-type-outer {
        width: 40% !important;
    }
    #offers-filter .form-inline .select2-container {
        width: 100% !important;
    }
    :not(#advanced_search_criteria) #offers-filter .select2-container.select2-container--open {
        max-width: 100% !important;
        width: 100% !important;
    }
}

@media (max-width: 330px) {
    #recruit-table thead tr th:nth-child(3) {
        width: auto !important;
        height: 24px;
        display: block !important;
    }
    #recruit-table tbody tr td:nth-child(3) {
        display: block !important;
    }
}

/* ==================
   Edit User Details
==================== */

.edit-user-details-img {
  border-radius: 50%;
  max-height: 100px;
}

/* ==================
  User Guidance
==================== */

#user-guidance {
  .circular-chart {
    width: 122px !important;
  }
  .circle-percentage {
    top: 49px;
    width: 122px;
    text-align: center;
    h4 {
      font-size: 25px;
    }
  }
  .descript-step {
      margin-bottom: 10px;
  }
  .descript-step p {
      font-size: 15px;
  }
  .btn-step-group {
      font-family: "KairosSans";
      text-transform: uppercase;
  }
  .view-step {
      background-color: #000;
      color: #fff;
      cursor: pointer;
      border-radius: 0px;
  }
  .skip-step {
      background-color: #E7F816;
      color: #000;
      cursor: pointer;
      border-radius: 0px;
  }
}
@media(max-width: 1109px) and (min-width: 768px) {
    #user-guidance #stepBox {
        height: auto !important;
    }
}

.freeze-bkgd {
  overflow-y: hidden;
  max-height: 100vh;
}

.select2-hidden-accessible.parsley-error  ~ .select2-container--default .select2-selection--single {
    border-color: #f34943 !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 115px;
    height: 34px;
}

.switch input {display:none;}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f0f0f0;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #E7F816;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(85px);
}

.on
{
    display: none;
}

.on, .off
{
    color: black;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;}

.hidden_arrow::-webkit-outer-spin-button,
.hidden_arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.hidden_arrow input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.verification-resend {
  display: inline-block;
}

.verification-resend .btn-link {
  border:none;
  outline:none;
  background:none;
  cursor:pointer;
  padding:0;
  text-decoration:underline;
  font-family:inherit;
  font-size:inherit;
}

